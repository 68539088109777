import { createMuiTheme } from '@material-ui/core/styles';

const AxleTheme = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#54BBBD',
        },
    },
});

AxleTheme.shape = {
    borderRadius: 10,
};

AxleTheme.typography.h5 = {
    fontSize: '16px',
};

AxleTheme.typography.h6 = {
    fontSize: '20px',
    fontWeight: 'bold',
};

AxleTheme.typography.subtitle1 = {
    fontSize: '24px',
    fontWeight: 'normal',
};

AxleTheme.typography.subtitle2 = {
    fontSize: '16px',
    fontWeight: 'normal',
};

export default AxleTheme;
