import axios from 'axios';

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
    config => {
        const apiHost =
            process.env.REACT_APP_API_HOST || 'http://localhost:8000';
        let apiUrl = `${apiHost}/qhp/v1`;

        config.url = apiUrl + config.url;
        config.headers.Accept = 'application/json';

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 401 || error.response.status === 403) {
            window.location.href = '/sign-in';
        }
        return Promise.reject(error);
    }
);

export const getCurrentVisit = () => {
    return axios.get(`/current-visit`, {
        withCredentials: true,
    });
};

export const getVisits = () => {
    return axios.get(`/visits`);
};

export const getVisit = id => {
    return axios.get(`/visits/${id}`);
};

export const changeVisitStage = (visitId, stageId) => {
    return axios.put(`/visits/${visitId}`, {
        next_status_id: stageId,
    });
};

export const getSurveyQuestions = () => {
    return axios.get(`/visit-complete`);
};

export const sendSurveyQuestions = data => {
    return axios.post(`/visit-complete`, data);
};

export const submitLogin = data => {
    return axios.post(`/auth`, data);
};

export const resetPassword = data => {
    return axios.post(`/reset-password`, data);
};

export const forgotPassword = data => {
    return axios.post(`/forgot-password`, data);
};

export const logout = () => {
    return axios.post(`/logout`);
};

export const updateLocation = data => {
    return axios.post(`/current-location`, data);
};
