import React, { useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Logo from '../../assets/logoCenterAligned.png';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Button, TextField } from '@material-ui/core';
import { forgotPassword } from '../../utils/api';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(12),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        color: '#fff',
    },
    logo: {
        width: '150px',
        marginBottom: theme.spacing(2),
    },
}));

export default function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const classes = useStyles();

    const handleSubmit = () => {
        forgotPassword({
            email: email,
        })
            .then(res => {
                if (res.status > 199 && res.status < 300) {
                    setIsSubmitted(true);
                }
            })
            .catch(err => console.error(err.message));
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <img src={Logo} className={classes.logo} />
                {isSubmitted && (
                    <Typography component="h1" variant="h5">
                        Check your email for a password reset link
                    </Typography>
                )}
                {!isSubmitted && (
                    <>
                        <Typography component="h1" variant="h5">
                            What is your email?
                        </Typography>
                        <div className={classes.form}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={handleSubmit}
                                disabled={email.length === 0}
                            >
                                Submit
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </Container>
    );
}
