import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Logo from '../../assets/logoCenterAligned.png';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useState } from 'react';
import queryString from 'query-string';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(12),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        color: '#fff',
    },
    logo: {
        width: '150px',
        marginBottom: theme.spacing(2),
    },
}));

export default function Home() {
    const [showSnackBar, setShowSnackbar] = useState(
        queryString.parse(location.search).success === 'true'
    );
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
                <img src={Logo} className={classes.logo} />
                <br />
                <br />
                <Typography component="h1" variant="h6">
                    Welcome!
                </Typography>
                <br />
                <Typography component="h1" variant="h5">
                    To access the healthcare professional app, download the Axle
                    Health mobile app on your iOS or Android device and login to
                    the mobile app using your username and password. If you have
                    questions on how to download the mobile app, please reach
                    out to your Axle Health point of contact.
                </Typography>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={showSnackBar}
                    autoHideDuration={20000}
                    onClose={() => setShowSnackbar(false)}
                >
                    <Alert>Success</Alert>
                </Snackbar>
            </div>
        </Container>
    );
}
