import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Logo from '../../assets/logoCenterAligned.png';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { resetPassword } from '../../utils/api';
import { Paper, Popper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(12),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
        color: '#fff',
    },
    logo: {
        width: '150px',
        marginBottom: theme.spacing(2),
    },
    passwordTooltip: {
        marginTop: theme.spacing(5 / 3),
        border: '1px solid lightgray',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        '&:after': {
            content: '""',
            position: 'absolute',
            top: '4px',
            left: '27px',
            marginLeft: '-5px',
            transform: 'translateY(-50%)',
            border: '11px solid #000',
            borderColor: 'transparent transparent white transparent',
            display: 'block',
        },
        '& > div:after': {
            content: '""',
            position: 'absolute',
            top: '2px',
            left: '26px',
            marginLeft: '-5px',
            transform: 'translateY(-50%)',
            border: '12px solid #000',
            borderColor: 'transparent transparent lightgray transparent',
            display: 'block',
        },
    },
    passwordTooltipItem: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 0, 0),
        color: 'lightgray',
        '& svg': {
            marginRight: theme.spacing(1),
        },
        '& p': {
            color: 'black',
        },
    },
}));

const passwordRequirements = [
    {
        title: 'Minimum 10 characters in length',
        validate: password => password && password.length >= 10,
    },
    {
        title: 'At least one capital letter',
        validate: password => /(.*[A-Z].*)/.test(password),
    },
    {
        title: 'At least one special character (!@#$%^&*)',
        validate: password => /(.*[!@#$%^&*].*)/.test(password),
    },
    {
        title: 'At least one number (0-9)',
        validate: password => /(.*\d.*)/.test(password),
    },
    {
        title: 'Cannot contain the phrase "password"',
        validate: password =>
            !/(.*password.*)/.test((password || '').toLowerCase()),
    },
];

const validatePassword = password => {
    var result = [];

    passwordRequirements.map(x => {
        if (!x.validate(password)) {
            result.push(x.title);
        }
    });

    return result;
};

export default function ResetPassword() {
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const classes = useStyles();
    const history = useHistory();

    const handleSubmit = () => {
        var errors = validatePassword(password);

        const token = queryString.parse(location.search).token;

        if (!token)
            errors.push(
                'No token in URL. Make sure you use the link in your email to set your password'
            );

        if (errors.length) {
            setErrors(errors);
            return;
        }

        setIsLoading(true);

        resetPassword({
            token: token,
            password: password,
        })
            .then(res => {
                if (res.status > 199 && res.status < 300) {
                    history.push('/?success=true');
                }
            })
            .catch(error => setErrors([error.response.data.message]));
        setIsLoading(false);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
        setErrors(null);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <img src={Logo} className={classes.logo} />
                <Typography component="h1" variant="h5">
                    Create a password
                </Typography>
                <div className={classes.form}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="New password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        onFocus={handlePopoverOpen}
                        onBlur={handlePopoverClose}
                    />
                    {errors &&
                        errors.map((x, idx) => (
                            <Typography
                                key={idx}
                                color="secondary"
                                align="left"
                            >
                                {x}
                            </Typography>
                        ))}
                    <PasswordFeedback
                        classes={classes}
                        anchorEl={anchorEl}
                        handlePopoverClose={handlePopoverClose}
                        open={Boolean(anchorEl)}
                        password={password}
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSubmit}
                        disabled={password.length === 0}
                        isLoading={isLoading}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </Container>
    );
}

const PasswordFeedback = ({ classes, anchorEl, open, password }) => {
    return (
        <Popper open={open} anchorEl={anchorEl} placement="bottom-start">
            {() => (
                <Paper className={classes.passwordTooltip}>
                    <div>
                        <Typography>
                            <b>Password requirements:</b>
                        </Typography>
                        {passwordRequirements.map((x, idx) => (
                            <div
                                key={idx}
                                className={classes.passwordTooltipItem}
                            >
                                <CheckCircleIcon
                                    color={
                                        x.validate(password)
                                            ? 'primary'
                                            : 'inherit'
                                    }
                                />
                                <Typography>{x.title}</Typography>
                            </div>
                        ))}
                    </div>
                </Paper>
            )}
        </Popper>
    );
};
