import React, { Component } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import ResetPassword from './routes/ResetPassword/ResetPassword';
import ForgotPassword from './routes/ForgotPassword/ForgotPassword';
import AxleTheme from './utils/theme';

import './App.css';
import Home from './routes/Home/Home';

class App extends Component {
    render() {
        return (
            <div className="App">
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route exact path="/reset-password">
                        <ResetPassword />
                    </Route>
                    <Route exact path="/forgot-password">
                        <ForgotPassword />
                    </Route>
                    <Redirect from="*" to="/" />
                </Switch>
            </div>
        );
    }
}

class AppWrapper extends React.Component {
    render() {
        return (
            <MuiThemeProvider theme={AxleTheme}>
                <Router>
                    <App />
                </Router>
            </MuiThemeProvider>
        );
    }
}

export default AppWrapper;
